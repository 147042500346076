import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip
} from '@mui/material';
import { Input, Menu } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { LocalStorage } from '../Services/LocalStorage/Localstorage.service';
import { AuthContext } from '../Context/AuthContext';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [, setLoginData] = useContext(AuthContext);

  const handleLogout = () => {
    LocalStorage.clear();
    setLoginData({});
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          {/* <Logo /> */}
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <Tooltip title="Logout">
            <IconButton color="inherit" onClick={handleLogout}>
              <Input />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
