import React, { createContext, useState } from "react";
import { LocalStorage } from "../Services/LocalStorage/Localstorage.service";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  //initial state
  let initialState = {
    email: null,
    id: null,
    access_token: null,
    role: null,
    name: null
  };

  //setting state to local storage if user exists
  if (LocalStorage.get("adminData")) {
    initialState = JSON.parse(LocalStorage.get("adminData"));
  }

  const [loginData, setLoginData] = useState(initialState);

  return (
    <AuthContext.Provider value={[loginData, setLoginData]}>
      {props.children}
    </AuthContext.Provider>
  );
};
