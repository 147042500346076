import React, { Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { roleFlag } from "../Services/Utils/Constants/roles.constant";
import PrivateRoute from "../Middleware/PrivateRoute";

const Dashboard = React.lazy(() => import("../Pages/Dashboard"));
const OrdersOngoing = React.lazy(() => import("../Pages/Orders/OrdersOngoing"));
const FutureOrders = React.lazy(() => import("../Pages/Orders/FutureOrders"));
const OrdersCompleted = React.lazy(() => import("../Pages/Orders/OrdersCompleted"));
const OrdersCancelled = React.lazy(() => import("../Pages/Orders/OrdersCancelled"));
const CustomerList = React.lazy(() => import("../Pages/Customers/List"));
const CustomerOrderHistory = React.lazy(() => import("../Pages/Customers/OrderHistory"));
const CategoryList = React.lazy(() => import("../Pages/Categories/List"));
const CreateCategory = React.lazy(() => import("../Pages/Categories/Create"));
const CategoryOrder = React.lazy(() => import("../Pages/Categories/CategoryOrdering"));
const SuggestedItems = React.lazy(() => import("../Pages/Categories/SuggestedItems"));
const MenuList = React.lazy(() => import("../Pages/MenuItems/List"));
const CreateMenu = React.lazy(() => import("../Pages/MenuItems/Create"));
const BranchList = React.lazy(() => import("../Pages/Branches/List"));
const CreateBranch = React.lazy(() => import("../Pages/Branches/Create"));
const BannerList = React.lazy(() => import("../Pages/Banners/List"));
const CreateBanner = React.lazy(() => import("../Pages/Banners/Create"));
const DeliveryChargeList = React.lazy(() => import("../Pages/DeliveryCharge/List"));
const SauceList = React.lazy(() => import("../Pages/Sauces/List"));
const CreateSauce = React.lazy(() => import("../Pages/Sauces/Create"));
const AddonsList = React.lazy(() => import("../Pages/AddOns/List"));
const CreateAddon = React.lazy(() => import("../Pages/AddOns/Create"));
const StoreTimeSettings = React.lazy(() => import("../Pages/Settings/StoreTime"));
const PeyalaCashSettings = React.lazy(() => import("../Pages/Settings/PeyalaCash"));
const BranchSettings = React.lazy(() => import("../Pages/Settings/BranchSettings"));
const ChangePassword = React.lazy(() => import("../Pages/Settings/ChangePassword"));
const SizeList = React.lazy(() => import("../Pages/Sizes/List"));
const CreateSize = React.lazy(() => import("../Pages/Sizes/Create"));
const DiscountList = React.lazy(() => import("../Pages/Discounts/List"));
const CreateDiscount = React.lazy(() => import("../Pages/Discounts/Create"));
const FaqList = React.lazy(() => import("../Pages/Faq/List"));
const CreateFaq = React.lazy(() => import("../Pages/Faq/Create"));
const AdminsList = React.lazy(() => import("../Pages/Admins/List"));
const ActivityLogsList = React.lazy(() => import("../Pages/Admins/ActivityLogs"));
const CreateAdmin = React.lazy(() => import("../Pages/Admins/Create"));
const Invoice = React.lazy(() => import("../Pages/Invoice"));
const PendingDeliveries = React.lazy(() => import("../Pages/Deliveries/PendingDeliveries/List"));
const CompletedDeliveries = React.lazy(() => import("../Pages/Deliveries/CompletedDeliveries/List"));
const Unauthorized = React.lazy(() => import("../Pages/Auth/Unauthorized"));

const allowAllExceptDel = [roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN, roleFlag.BRANCH_ADMIN, roleFlag.BRANDING];
const allowAll = [roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN, roleFlag.BRANCH_ADMIN, roleFlag.BRANDING, roleFlag.DELIVERY];

const AppRoutes = (
    <Suspense fallback={
        <LinearProgress />
    }>
        <Switch>
            <PrivateRoute path="/dashboard" component={Dashboard} allowedRole={allowAllExceptDel} />
            <PrivateRoute path="/orders-completed" component={OrdersCompleted} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN, roleFlag.BRANCH_ADMIN]} />
            <PrivateRoute path="/orders-ongoing" component={OrdersOngoing} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN, roleFlag.BRANCH_ADMIN]} />
            <PrivateRoute path="/orders-cancelled" component={OrdersCancelled} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN, roleFlag.BRANCH_ADMIN]} />
            <PrivateRoute path="/orders-scheduled" component={FutureOrders} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN, roleFlag.BRANCH_ADMIN]} />
            <PrivateRoute path="/customer-list" component={CustomerList} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/customer-order-history" component={CustomerOrderHistory} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/category-list" component={CategoryList} allowedRole={allowAllExceptDel} />
            <PrivateRoute path="/create-category" component={CreateCategory} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/category-order" component={CategoryOrder} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/suggested-menu" component={SuggestedItems} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/menu-list" component={MenuList} allowedRole={allowAllExceptDel} />
            <PrivateRoute path="/create-menu" component={CreateMenu} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/branch-list" component={BranchList} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN]} />
            <PrivateRoute path="/create-branch" component={CreateBranch} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.OPERATION_ADMIN]} />
            <PrivateRoute path="/banner-list" component={BannerList} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/create-banner" component={CreateBanner} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/delivery-charge-module" component={DeliveryChargeList} allowedRole={[roleFlag.SUPER_ADMIN]} />
            <PrivateRoute path="/sauces" component={SauceList} allowedRole={allowAllExceptDel} />
            <PrivateRoute path="/create-sauce" component={CreateSauce} allowedRole={allowAllExceptDel} />
            <PrivateRoute path="/addons-list" component={AddonsList} allowedRole={allowAllExceptDel} />
            <PrivateRoute path="/create-addon" component={CreateAddon} allowedRole={allowAllExceptDel} />
            <PrivateRoute path="/size-list" component={SizeList} allowedRole={allowAllExceptDel} />
            <PrivateRoute path="/create-size" component={CreateSize} allowedRole={allowAllExceptDel} />
            <PrivateRoute path="/voucher-list" component={DiscountList} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/create-voucher" component={CreateDiscount} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/store-time-settings" component={StoreTimeSettings} allowedRole={[roleFlag.SUPER_ADMIN]} />
            <PrivateRoute path="/peyala-cash-settings" component={PeyalaCashSettings} allowedRole={[roleFlag.SUPER_ADMIN]} />
            <PrivateRoute path="/branch-settings" component={BranchSettings} allowedRole={[roleFlag.BRANCH_ADMIN]} />
            <PrivateRoute path="/change-password" component={ChangePassword} allowedRole={allowAll} />
            <PrivateRoute path="/faq-list" component={FaqList} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/create-faq" component={CreateFaq} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.BRANDING]} />
            <PrivateRoute path="/admin-list" component={AdminsList} allowedRole={[roleFlag.SUPER_ADMIN]} />
            <PrivateRoute path="/create-admin" component={CreateAdmin} allowedRole={[roleFlag.SUPER_ADMIN]} />
            <PrivateRoute path="/activity-list" component={ActivityLogsList} allowedRole={[roleFlag.SUPER_ADMIN]} />
            <PrivateRoute path="/view-invoice/:id" component={Invoice} allowedRole={[roleFlag.SUPER_ADMIN]} />
            <PrivateRoute path="/pending-deliveries" component={PendingDeliveries} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.DELIVERY]} />
            <PrivateRoute path="/completed-deliveries" component={CompletedDeliveries} allowedRole={[roleFlag.SUPER_ADMIN, roleFlag.DELIVERY]} />
            <Route path="/unauthorized" component={Unauthorized} />

            <Redirect to="/dashboard" />
        </Switch>
    </Suspense>

);

export default AppRoutes;
