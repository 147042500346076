import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  ListItem,
  Collapse,
} from '@mui/material';
import {
  BarChart,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Logout,
  Settings,
  ShoppingBag,
  RestaurantMenu,
  People,
  FoodBank,
  Fastfood,
  Announcement,
  Redeem,
  Bento,
  HelpCenter,
  Store,
  DeliveryDining,
  AccountCircle,
  AddCircle,
  AccessTime,
  DirectionsBike,
  Analytics
} from '@mui/icons-material';
import { useStyles } from './Navbar.styles';
import NavUserDetails from './NavUserDetails';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AuthContext } from '../../Context/AuthContext';
import { LocalStorage } from '../../Services/LocalStorage/Localstorage.service';
import { useMemo } from 'react';

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [openCustomers, setOpenCustomers] = React.useState(false);
  const [openCategory, setOpenCategory] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [openDeliveries, setOpenDeliveries] = React.useState(false);
  const history = useHistory();
  const [, setLoginData] = useContext(AuthContext);

  const role = useMemo(() => {
    return LocalStorage.get('adminData') ? JSON.parse(LocalStorage.get('adminData')).role : '';
  }, []);

  const isSuper = role === 'super';
  const isOps = role === 'operation';
  const isBranch = role === 'branch';
  const isBranding = role === 'branding';
  const isDelivery = role === 'delivery';

  const handleClick = (type) => {
    if (type === 'orders') {
      setOpen(!open);
      setOpenCustomers(false)
      setOpenCategory(false);
      setOpenDeliveries(false);
    }
    if (type === 'customers') {
      setOpen(false);
      setOpenCustomers(!openCustomers)
      setOpenCategory(false);
      setOpenDeliveries(false);
    }
    if (type === 'settings') {
      setOpen(false);
      setOpenCustomers(false)
      setOpenSettings(!openSettings)
      setOpenCategory(false);
      setOpenDeliveries(false);
    }
    if (type === 'categories') {
      setOpen(false);
      setOpenCustomers(false)
      setOpenSettings(false)
      setOpenCategory(!openCategory);
      setOpenDeliveries(false);
    }
    if (type === 'deliveries') {
      setOpen(false);
      setOpenCustomers(false)
      setOpenSettings(false)
      setOpenCategory(false);
      setOpenDeliveries(!openDeliveries);
    }
  };

  const handleLogout = () => {
    LocalStorage.clear();
    setLoginData({});
  };

  const toggleFunction = () => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  };

  useEffect(() => {
    toggleFunction();
    // eslint-disable-next-line
  }, []);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar>
        <Box
          p={2}
        >
          <NavUserDetails />
        </Box>
        <Divider />
        <Box p={2}>
          <List>
            {(isSuper || isOps || isBranch) &&
              <ListItem className={classes.listItem} button component={RouterLink} to={'/dashboard'} >
                <BarChart className={classes.icon} size={20} />
                <Typography variant="body2">Dashboard</Typography>
              </ListItem>}
            {(isSuper || isOps || isBranch) &&
              <>
                <ListItem className={classes.listItem} onClick={() => handleClick('orders')}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <ShoppingBag className={classes.icon} size={20} />
                      <Typography variant="body2">Orders</Typography>
                    </div>
                    {open ? <KeyboardArrowUp size={20} /> : <KeyboardArrowDown size={20} />}
                  </div>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem className={classes.nested} component={RouterLink} to='/orders-ongoing'>
                      <Typography variant="body2" pl={2}>Orders Ongoing</Typography>
                    </ListItem>
                    <ListItem className={classes.nested} component={RouterLink} to='/orders-scheduled'>
                      <Typography variant="body2" pl={2}>Orders Scheduled</Typography>
                    </ListItem>
                    <ListItem className={classes.nested} component={RouterLink} to='/orders-completed'>
                      <Typography variant="body2" pl={2}>Orders Completed</Typography>
                    </ListItem>
                    <ListItem className={classes.nested} component={RouterLink} to='/orders-cancelled'>
                      <Typography variant="body2" pl={2}>Orders Cancelled</Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </>
            }

            {(isSuper || isBranding) &&
              <>
                <ListItem className={classes.listItem} button onClick={() => handleClick('customers')}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <People className={classes.icon} size={20} />
                      <Typography variant="body2">Customers</Typography>
                    </div>
                    {openCustomers ? <KeyboardArrowUp size={20} /> : <KeyboardArrowDown size={20} />}
                  </div>
                </ListItem>
                <Collapse in={openCustomers} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>

                    {(isSuper || isBranding) &&
                      <ListItem button className={classes.nested} onClick={() => history.push('/customer-list', {
                        state: {
                          title: 'Customers'
                        }
                      })}>
                        <Typography variant="body2" pl={2}>Customer List</Typography>
                      </ListItem>}
                    <ListItem button className={classes.nested} component={RouterLink} to='/customer-order-history'>
                      <Typography variant="body2" pl={2}>Customer Order History</Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </>
            }

            {/* {(isSuper) && */}
            <>
              <ListItem className={classes.listItem} onClick={() => handleClick('categories')}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FoodBank className={classes.icon} size={20} />
                    <Typography variant="body2">Menu Categories</Typography>
                  </div>
                  {openCategory ? <KeyboardArrowUp size={20} /> : <KeyboardArrowDown size={20} />}
                </div>
              </ListItem>
              <Collapse in={openCategory} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {
                    (isSuper || isBranch || isBranding || isOps) &&
                    <ListItem className={classes.nested} onClick={() => history.push('/category-list', {
                      state: {
                        title: 'Categories'
                      }
                    })}>
                      <Typography variant="body2" pl={2}>Category List</Typography>
                    </ListItem>
                  }
                  {
                    (isSuper || isBranding) &&
                    <ListItem className={classes.nested} component={RouterLink} to='/category-order'>
                      <Typography variant="body2" pl={2}>Category Ordering</Typography>
                    </ListItem>
                  }

                  {
                    (isSuper || isBranding) &&
                    <ListItem className={classes.nested} component={RouterLink} to='/suggested-menu'>
                      <Typography variant="body2" pl={2}>Suggested Items</Typography>
                    </ListItem>}

                </List>
              </Collapse>

              {
                (isSuper || isBranding || isBranch || isOps) &&
                <ListItem className={classes.listItem} button component={RouterLink} to={'/menu-list'}>
                  <RestaurantMenu className={classes.icon} size={20} />
                  <Typography variant="body2">Menu Items</Typography>
                </ListItem>
              }
            </>
            {/* } */}
            {(isSuper || isOps) &&
              <ListItem className={classes.listItem} component={RouterLink} to={'/branch-list'}>
                <Store className={classes.icon} size={20} />
                <Typography variant="body2">Branches</Typography>
              </ListItem>
            }

            {(isSuper) &&
              <ListItem className={classes.listItem} component={RouterLink} to={'/delivery-charge-module'}>
                <DeliveryDining className={classes.icon} size={20} />
                <Typography variant="body2">Delivery Charge Module</Typography>
              </ListItem>
            }

            {(isSuper) &&
              <ListItem className={classes.listItem} component={RouterLink} to={'/store-time-settings'}>
                <AccessTime className={classes.icon} size={20} />
                <Typography variant="body2">Store Time</Typography>
              </ListItem>
            }

            {(isSuper || isOps || isBranding || isBranch) &&
              <ListItem className={classes.listItem} component={RouterLink} to={'/addons-list'}>
                <AddCircle className={classes.icon} size={20} />
                <Typography variant="body2">Add Ons</Typography>
              </ListItem>
            }

            {(isSuper || isOps || isBranding || isBranch) &&
              <ListItem className={classes.listItem} component={RouterLink} to={'/sauces'}>
                <Fastfood className={classes.icon} size={20} />
                <Typography variant="body2">Sauces</Typography>
              </ListItem>
            }

            {(isSuper || isOps || isBranding || isBranch) &&
              <ListItem className={classes.listItem} button component={RouterLink} to={'/size-list'}>
                <Bento className={classes.icon} size={20} />
                <Typography variant="body2">Sizes</Typography>
              </ListItem>
            }

            {
              (isSuper) && <ListItem className={classes.listItem} button component={RouterLink} to={'/voucher-list'}>
                <Redeem className={classes.icon} size={20} />
                <Typography variant="body2">Vouchers</Typography>
              </ListItem>
            }
            {
              (isSuper || isBranding) && <ListItem className={classes.listItem} button component={RouterLink} to={'/banner-list'}>
                <Announcement className={classes.icon} size={20} />
                <Typography variant="body2">Banners</Typography>
              </ListItem>
            }

            {(isSuper || isBranding) && <ListItem className={classes.listItem} button component={RouterLink} to={'/faq-list'}>
              <HelpCenter className={classes.icon} size={20} />
              <Typography variant="body2">FAQ</Typography>
            </ListItem>
            }

            {/* DELIVERIES */}
            {isDelivery &&
              <>
                <ListItem className={classes.listItem} button onClick={() => handleClick('deliveries')}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <DirectionsBike className={classes.icon} size={20} />
                      <Typography variant="body2">Deliveries</Typography>
                    </div>
                    {openDeliveries ? <KeyboardArrowUp size={20} /> : <KeyboardArrowDown size={20} />}
                  </div>
                </ListItem>
                <Collapse in={openDeliveries} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested} component={RouterLink} to='/pending-deliveries'>
                      <Typography variant="body2" pl={2}>Pending Deliveries</Typography>
                    </ListItem>
                  </List>
                </Collapse>

                <Collapse in={openDeliveries} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested} component={RouterLink} to='/completed-deliveries'>
                      <Typography variant="body2" pl={2}>Completed Deliveries</Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </>
            }

            {/* SETTINGS */}
            <ListItem className={classes.listItem} button onClick={() => handleClick('settings')}>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Settings className={classes.icon} size={20} />
                  <Typography variant="body2">Settings</Typography>
                </div>
                {openSettings ? <KeyboardArrowUp size={20} /> : <KeyboardArrowDown size={20} />}
              </div>
            </ListItem>

            {
              (isSuper) &&
              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} component={RouterLink} to='/peyala-cash-settings'>
                    <Typography variant="body2" pl={2}>Peyala Cash</Typography>
                  </ListItem>
                </List>
              </Collapse>
            }

            <Collapse in={openSettings} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} component={RouterLink} to='/change-password'>
                  <Typography variant="body2" pl={2}>Change Password</Typography>
                </ListItem>
              </List>
            </Collapse>

            {(isBranch) && <Collapse in={openSettings} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} component={RouterLink} to='/branch-settings'>
                  <Typography variant="body2" pl={2}>Branch Settings</Typography>
                </ListItem>
              </List>
            </Collapse>}

            {/* ADMINS */}
            {(isSuper) &&
              <ListItem className={classes.listItem} button component={RouterLink} to={'/admin-list'}>
                <AccountCircle className={classes.icon} size={20} />
                <Typography variant="body2">Admins</Typography>
              </ListItem>
            }

            {/* ACTIVITY LOGS */}
            {(isSuper) &&
              <ListItem className={classes.listItem} button component={RouterLink} to={'/activity-list'}>
                <Analytics className={classes.icon} size={20} />
                <Typography variant="body2">Activity Logs</Typography>
              </ListItem>
            }

            {
              openMobile ?
                <React.Fragment>
                  <Divider />
                  <ListItem onClick={handleLogout} className={classes.listItem} button>
                    <Logout className={classes.icon} size={20} />
                    <Typography variant="body2">Logout</Typography>
                  </ListItem>
                </React.Fragment> : ''
            }

          </List>
        </Box>
        <Box flexGrow={1} />
      </PerfectScrollbar>
    </Box>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;