import React, { useContext } from 'react';
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from '../Context/AuthContext';

const checkRoleExists = (role, roleArr) => {
    for (let i = 0; i < roleArr.length; i++) {
        if (roleArr[i].match(role)) return true;
    }
    return false;
}

const PrivateRoute = ({ component, path, allowedRole }) => {

    const [loginData] = useContext(AuthContext);
    const authed = loginData.email
    const currentRole = loginData.role; //get from the cookies

    if (authed) {
        //check if the current role is allowed to view this page
        if (checkRoleExists(currentRole, allowedRole)) {
            return <Route path={path} component={component} />
        } else {
            //redirect to unauthorized page
            return <Redirect to="/unauthorized" />
        }
    } else {
        //logout
    }
}

export default PrivateRoute;