const customTypography = {
    h1: {
        fontWeight: 500,
        fontSize: 35,
        letterSpacing: '-0.24px',
        fontFamily: "Roboto"

    },
    h2: {
        fontWeight: 500,
        fontSize: 29,
        letterSpacing: '-0.24px',
        fontFamily: "Roboto"

    },
    h3: {
        fontWeight: 500,
        fontSize: 24,
        letterSpacing: '-0.06px',
        fontFamily: "Roboto"

    },
    h4: {
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: '-0.06px',
        fontFamily: "Roboto"

    },
    h5: {
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: '-0.05px',
        fontFamily: "Roboto"

    },
    h6: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: '-0.05px',
        fontFamily: "Roboto"

    },
    overline: {
        fontWeight: 500
    },
    button: {
        textTransform: 'capitalize'
    }
};

export default customTypography;
