import { LinearProgress } from "@mui/material";
import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

const Login = React.lazy(() => import("../Pages/Auth/Login"));
const ForgotPassword = React.lazy(() => import("../Pages/Auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../Pages/Auth/ResetPassword"));
const VerifyAccount = React.lazy(() => import("../Pages/Auth/VerifyAccount"));

const AuthRoutes = (
    <Suspense fallback={
        <LinearProgress />
    }>
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/verify-account" component={VerifyAccount} />
            <Redirect to="/login" />
        </Switch>
    </Suspense>
);

export default AuthRoutes;
