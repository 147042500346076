import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { useStyles } from './Navbar.styles';
import { LocalStorage } from '../../Services/LocalStorage/Localstorage.service';
import peyalaLogo from '../../assets/images/peyala_logo.png';

const user = {
  avatar: peyalaLogo,
};

const NavUserDetails = () => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} mb={2} align="center">
        <Avatar
          className={classes.avatar}
          src={user.avatar}
        />
      </Grid>
      <Grid item xs={12} align="center">
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h4"
        >
          {JSON.parse(LocalStorage.get('adminData')).name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {JSON.parse(LocalStorage.get('adminData')).role === 'super' ? 'Super Admin' : JSON.parse(LocalStorage.get('adminData')).role === 'operation' ? 'Operation Admin': JSON.parse(LocalStorage.get('adminData')).role === 'branding' ? 'Branding' : JSON.parse(LocalStorage.get('adminData')).branch_name + " Branch"}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default NavUserDetails;
