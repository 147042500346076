import "./App.css";
import { BrowserRouter } from "react-router-dom";
import 'react-perfect-scrollbar/dist/css/styles.css';
import AuthRoutes from "./Routes/AuthRoutes";
import AppRoutes from "./Routes/AppRoutes";
import Layout from "./Layouts";
import GlobalStyles from "./Components/GlobalStyles";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { AuthContext } from "./Context/AuthContext";
import { useContext } from "react";

const App = () => {

  const [loginData] = useContext(AuthContext);

  let routes = AuthRoutes;
  let authed = loginData.email;

  if (authed) {
    routes = <Layout>{AppRoutes}</Layout>;
  } else {
    routes = AuthRoutes;
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalStyles />
        {routes}
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
